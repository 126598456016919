import { SiteConfig } from "../../types";
import { invitationTemplate } from "./invitation";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "Inicijativa",
  pwaServiceName: "Inicijativa",
  appLogoPath: "/partner/inicijativa/logo.png",
  appLogoHeight: "40px",
  landingLogoPath: "/partner/inicijativa/logo.png",
  landingLogoHeight: "60px",
  favicon: "/partner/inicijativa/fav-icon.png",
  pwaIcons: [
    {
      src: "/partner/inicijativa/pwa-icon-192.png",
      type: "image/png",
      sizes: "192x192",
    },
    {
      src: "/partner/inicijativa/pwa-icon-256.png",
      type: "image/png",
      sizes: "256x256",
    },
    {
      src: "/partner/inicijativa/pwa-icon-512.png",
      type: "image/png",
      sizes: "512x512",
    },
  ],
  hubFixedPrice: true,
  overrideLanding: true,
  emailsSender: "inicijativa@inicijativa.biz",
  adminEmail: "inicijativa@inicijativa.biz",
  practiceLanguageOptions: ["en-US", "en-GB", "en-AU", "en-IE", "en-NZ", "en-CA", "en-IN", "hr-HR"],
  reviewLink: "https://g.page/r/CdVG1RJGTncGEBM/review",
  adminPhoneNumber: "0038514818542",
  tocLink: "https://inicijativa.biz/izjava-o-privatnosti-podataka/",
  privacyLink: "https://inicijativa.biz/izjava-o-privatnosti-podataka/",
  emailInvitationSubject: "You have been invited to Inicijativa!",
  emailInvitationHtml: invitationTemplate,
  copyright: "Inicijativa",
  showLanguageGuides: false,
  showAccountUsage: true,
  bioPrompt: `
    You are a tutor at Inicijativa, a language school in Croatia.
    `,
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#eee",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#222",
      mobileNavBackgroundColor: "#786658",
      mobileNavBackgroundContrastColor: "#ffffff",
      mobileNavBorderColor: "#786658",
      speakInputIdle: "var(--chakra-colors-brand-primary-600)",
      speakInputRecording: "var(--chakra-colors-brand-primary-900)",
      //chatIconColor: "var(--chakra-colors-brand-primary-main)",
    },
  },
  colors: {
    primary: {
      main: "#35c8ff",
      mainContrast: "#000",
      "50": "#ecfaff",
      "100": "#aee9ff",
      "200": "#5dd3ff",
      "300": "#2fb2e3",
      "400": "#2a9fcb",
      "500": "#2486ab",
      "600": "#1e7191",
      "700": "#185b74",
      "800": "#144d62",
      "900": "#0f3847",
    },

    tertiary: {
      main: "#e00918",
      mainContrast: "#fff",
      "50": "#fef5f6",
      "100": "#fad8da",
      "200": "#f6b5ba",
      "300": "#f0888f",
      "400": "#ec6b74",
      "500": "#e63b47",
      "600": "#d40917",
      "700": "#ac0712",
      "800": "#930610",
      "900": "#6d040c",
    },
    secondary: {
      main: "#f6a20b",
      mainContrast: "#000",
      "50": "#fef6e9",
      "100": "#fcdca4",
      "200": "#f8bb4d",
      "300": "#e3950a",
      "400": "#cb8609",
      "500": "#ab7108",
      "600": "#905f06",
      "700": "#744c05",
      "800": "#624104",
      "900": "#472f03",
    },
    light: {
      main: "#f2f2f2",
      alt: "#f2f2f2",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    cyan: {
      "50": "#f2fbff",
      "100": "#c6f0ff",
      "200": "#abe9ff",
      "300": "#8be0ff",
      "400": "#33c2f6",
      "500": "#2fb2e2",
      "600": "#2aa1cc",
      "700": "#2385a8",
      "800": "#1d6d8a",
      "900": "#16546b",
    },
    blue: {
      "50": "#f1f6ff",
      "100": "#cadfff",
      "200": "#a3c8ff",
      "300": "#76adff",
      "400": "#4a92ff",
      "500": "#317cec",
      "600": "#2968c5",
      "700": "#1f4f97",
      "800": "#1a417c",
      "900": "#153565",
    },
    purple: {
      "50": "#f9f5ff",
      "100": "#e9d8ff",
      "200": "#d8baff",
      "300": "#bf8eff",
      "400": "#ad6eff",
      "500": "#923eff",
      "600": "#7a2edd",
      "700": "#6526b6",
      "800": "#531f96",
      "900": "#3e176f",
    },
    pink: {
      "50": "#fff5f9",
      "100": "#ffd6e7",
      "200": "#ffb4d2",
      "300": "#ff81b3",
      "400": "#ff5499",
      "500": "#e22f77",
      "600": "#c22866",
      "700": "#9f2153",
      "800": "#7c1a41",
      "900": "#5c1330",
    },
    red: {
      "50": "#fff5f4",
      "100": "#ffd7d3",
      "200": "#ffb2aa",
      "300": "#ff8072",
      "400": "#ff5d4c",
      "500": "#e4412f",
      "600": "#c13728",
      "700": "#9b2c20",
      "800": "#84261b",
      "900": "#601b14",
    },
    orange: {
      "50": "#fffaf2",
      "100": "#ffeacc",
      "200": "#ffd18d",
      "300": "#fbab34",
      "400": "#d9942d",
      "500": "#bb7f27",
      "600": "#9e6c21",
      "700": "#7e561a",
      "800": "#634315",
      "900": "#513711",
    },
    yellow: {
      "50": "#fffef6",
      "100": "#fffbc5",
      "200": "#fef135",
      "300": "#efe332",
      "400": "#dbd02d",
      "500": "#b4ab25",
      "600": "#90891e",
      "700": "#706b17",
      "800": "#545011",
      "900": "#45420e",
    },
    green: {
      "50": "#eefff8",
      "100": "#95ffd5",
      "200": "#32f2a5",
      "300": "#2dd893",
      "400": "#27bd81",
      "500": "#22a26f",
      "600": "#1c865c",
      "700": "#166847",
      "800": "#12563a",
      "900": "#0f4630",
    },
    teal: {
      "50": "#ebfdff",
      "100": "#a2f6ff",
      "200": "#35e9fd",
      "300": "#2fd0e2",
      "400": "#28b1c0",
      "500": "#2296a3",
      "600": "#1c7a85",
      "700": "#155f67",
      "800": "#124f56",
      "900": "#0f4147",
    },
  },
  templates: [
    {
      type: "free-talk",
      titleKey: "dashboard.template.freeTalk.title",
      descriptionKey: "dashboard.template.freeTalk.description",
      backgroundColor: "brand.primary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "role-play",
      titleKey: "dashboard.template.rolePlay.title",
      descriptionKey: "dashboard.template.rolePlay.description",
      backgroundColor: "brand.yellow.100",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "topic",
      titleKey: "dashboard.template.topics.title",
      descriptionKey: "dashboard.template.topics.description",
      backgroundColor: "brand.red.400",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "quiz",
      titleKey: "dashboard.template.quiz.title",
      descriptionKey: "dashboard.template.quiz.description",
      backgroundColor: "brand.green.300",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "case",
      titleKey: "dashboard.template.caseStudy.title",
      descriptionKey: "dashboard.template.caseStudy.description",
      backgroundColor: "brand.blue.400",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
  ],
};
