import { SiteConfig } from "../../types";
import { _config as spracheundwissenConfig } from "../spracheundwissen.com/config";
import { _config as chengxueluConfig } from "../chengxuelu.com/config";
import { _config as punkifyConfig } from "../punkify.com/config";
import { _config as clubedealemaoConfig } from "../clubedealemao.com/config";
import { _config as englishbisaConfig } from "../englishbisa.com/config";
import { _config as frenchamiConfig } from "../frenchami.com/config";
import { _config as lingoprofConfig } from "../lingoprof.com/config";
import { _config as elingwistaConfig } from "../elingwista.com/config";
import { _config as gliConfig } from "../gli.demo/config";
import { _config as berlitzConfig } from "../berlitz.com/config";
import { _config as saySoConfig } from "../say-so.pl/config";
import { _config as iliEduskyConfig } from "../ili-edusky.pl/config";
import { _config as inicijativaConfig } from "../inicijativa.biz/config";
import { _config as magyarIskolaConfig } from "../magyar-iskola.hu/config";
import { _config as emAcademyConfig } from "../emacademy.eu/config";
import { _config as leventEnglishConfig } from "../leventenglish.com/config";
import { _config as talkioKoreaConfig } from "../rmp.talkio.ai/config";
import { _config as teachersTeamConfig } from "../pogad.ai/config";
import { _config as defaultConfig } from "../../default/config";

// export const _config: SiteConfig = spracheundwissenConfig;
// export const _config: SiteConfig = punkifyConfig;
// export const _config: SiteConfig = clubedealemaoConfig;
// export const _config: SiteConfig = englishbisaConfig;
// export const _config: SiteConfig = gliConfig;
// export const _config: SiteConfig = frenchamiConfig;
// export const _config: SiteConfig = lingoprofConfig;
// export const _config: SiteConfig = chengxueluConfig;
// export const _config: SiteConfig = elingwistaConfig;
// export const _config: SiteConfig = berlitzConfig;
// export const _config: SiteConfig = saySoConfig;
// export const _config: SiteConfig = iliEduskyConfig;
// export const _config: SiteConfig = inicijativaConfig;
// export const _config: SiteConfig = magyarIskolaConfig;
// export const _config: SiteConfig = emAcademyConfig;
// export const _config: SiteConfig = leventEnglishConfig;
// export const _config: SiteConfig = talkioKoreaConfig;
// export const _config: SiteConfig = teachersTeamConfig;
export const _config: SiteConfig = defaultConfig;
