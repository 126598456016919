import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: true,
  serviceName: "Talkio AI",
  appLogoPath: "/talkio-logo-white.svg",
  appLogoHeight: "22px",
  landingLogoPath: "/talkio-logo.svg",
  landingLogoHeight: "25px",
  favicon: "/favicon.png",
  showTidio: true,
  showLanguageGuides: false,
  reviewLink: "https://senja.io/p/talkio/r/9XyUN8",
  overrideLanding: true,
};
