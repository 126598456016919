import { SiteConfig } from "../../types";
import { invitationTemplate } from "./invitation";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "pogad.ai",
  pwaServiceName: "pogad.ai",
  // authErrorPath: "pogad.ai/login-error",
  privacyLink: "https://pogad.ai/polityka-prywatnosci/",
  adminEmail: "admin@pogad.ai",
  adminPhoneNumber: "+48 791 615 011",
  appLogoPath: "/partner/pogad/POGADai_logo_OK_ALT_2_B_fullcolor.png",
  appLogoHeight: "70px",
  landingLogoPath: "/partner/pogad/POGADai_logo_OK_ALT_2_B_fullcolor.png",
  hubLogoPath: "/partner/pogad/POGADai_logo_OK_ALT_2_B_fullcolor.png",
  hubLogoHeight: "50px",
  hubFixedPrice: true,
  landingLogoHeight: "60px",
  mobileLogoPath: "/partner/pogad/mobile-logo.png",
  favicon: "/partner/pogad/favicon.ico",
  pwaIcons: [
    {
      src: "/partner/pogad/pwa-192x192.png",
      type: "image/png",
      sizes: "192x192",
    },
    {
      src: "/partner/pogad/pwa-512x512.png",
      type: "image/png",
      sizes: "512x512",
    },
  ],
  overrideLanding: true,
  emailsSender: "info@lang-services.com",
  emailInvitationHtml: invitationTemplate,
  supportLink: "https://pogad.ai/kontakt/",
  reviewLink: null,
  emailInvitationSubject: "pogad.AI z lektorem - zaproszenie czeka na Ciebie!",
  copyright: "TEACHERSteam.pl 2025",
  showLanguageGuides: true,
  bioPrompt: `
    Witamy w TEACHERSteam. Nieprzerwanie od roku 2005 zapewniamy kompleksową obsługę językową kilkuset firm w całej Polsce. Teraz jesteśmy jeszcze skuteczniejsi dzięki AI.
    `,
  fonts: {
    heading: `"Blinker", sans-serif`,
    body: `"Montserrat", sans-serif`,
  },
  fontLink: "/partner/pogad/fonts.css",
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#eee",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#1D1D1D",
      mobileNavBackgroundColor: "white",
      mobileNavBackgroundContrastColor: "#000",
      mobileNavBorderColor: "#eee",
      speakInputIdle: "var(--chakra-colors-brand-secondary-600)",
      speakInputRecording: "var(--chakra-colors-brand-primary-900)",
      chatIconColor: "var(--chakra-colors-brand-tertiary-main)",
    },
  },
  colors: {
    primary: {
      main: "#f16720",
      mainContrast: "#fff",
      "50": "#fef6f1",
      "100": "#fcd9c8",
      "200": "#f8b796",
      "300": "#f48a53",
      "400": "#f16c27",
      "500": "#cf591c",
      "600": "#af4b17",
      "700": "#8d3c13",
      "800": "#773310",
      "900": "#56250b",
    },
    secondary: {
      main: "#78c6c3",
      mainContrast: "#000000",
      "50": "#f1f9f9",
      "100": "#c5e7e5",
      "200": "#92d1ce",
      "300": "#6cb2af",
      "400": "#609f9c",
      "500": "#518684",
      "600": "#44716f",
      "700": "#375b59",
      "800": "#2e4d4b",
      "900": "#213736",
    },
    tertiary: {
      main: "#2d3748",
      mainContrast: "#fff",
      "50": "#f7f7f8",
      "100": "#dfe0e3",
      "200": "#c3c6cb",
      "300": "#a2a6ae",
      "400": "#8f959e",
      "500": "#777d88",
      "600": "#626a76",
      "700": "#4c5563",
      "800": "#3f4857",
      "900": "#2a3444",
    },
    light: {
      main: "#f2f3f5",
      alt: "#f2f3f5",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    orange: {
      "50": "#fefaf5",
      "100": "#fdead8",
      "200": "#fad2a9",
      "300": "#f5ad63",
      "400": "#ef8820",
      "500": "#ce751b",
      "600": "#ae6317",
      "700": "#8b4f12",
      "800": "#6d3e0f",
      "900": "#5a330c",
    },
    yellow: {
      "50": "#fffefb",
      "100": "#fdf9e5",
      "200": "#faeeb0",
      "300": "#f6df6f",
      "400": "#ecca1f",
      "500": "#c2a61a",
      "600": "#9c8515",
      "700": "#796810",
      "800": "#5b4e0c",
      "900": "#4b400a",
    },
    green: {
      "50": "#f2fef8",
      "100": "#b2fad6",
      "200": "#37f295",
      "300": "#1dd97c",
      "400": "#19be6c",
      "500": "#16a45d",
      "600": "#12874d",
      "700": "#0e693c",
      "800": "#0b5631",
      "900": "#094728",
    },
    teal: {
      "50": "#eafefe",
      "100": "#96f8f8",
      "200": "#1fecec",
      "300": "#1cd3d3",
      "400": "#18b3b3",
      "500": "#149898",
      "600": "#107c7c",
      "700": "#0d6060",
      "800": "#0b5050",
      "900": "#094242",
    },
    cyan: {
      "50": "#f1fcfe",
      "100": "#c1f1fb",
      "200": "#a3ebf9",
      "300": "#7fe3f7",
      "400": "#1fc5e6",
      "500": "#1cb5d4",
      "600": "#19a3bf",
      "700": "#15879e",
      "800": "#116f82",
      "900": "#0d5664",
    },
    blue: {
      "50": "#f0f7fe",
      "100": "#c6e1fb",
      "200": "#9ccaf9",
      "300": "#6cb0f6",
      "400": "#3b97f3",
      "500": "#1e80e2",
      "600": "#196bbd",
      "700": "#135291",
      "800": "#104377",
      "900": "#0d3761",
    },
    purple: {
      "50": "#f8f6fe",
      "100": "#e5d9fd",
      "200": "#d2bdfb",
      "300": "#b594f8",
      "400": "#a176f6",
      "500": "#854cf4",
      "600": "#6d2bf2",
      "700": "#581cd1",
      "800": "#4917ac",
      "900": "#371181",
    },
    pink: {
      "50": "#fef5fa",
      "100": "#fcd7ea",
      "200": "#fab5d8",
      "300": "#f784be",
      "400": "#f55ca9",
      "500": "#e71f83",
      "600": "#c71a71",
      "700": "#a2165c",
      "800": "#801149",
      "900": "#5f0d36",
    },
    red: {
      "50": "#fef5f5",
      "100": "#fdd7d7",
      "200": "#fab4b4",
      "300": "#f78484",
      "400": "#f56565",
      "500": "#f12828",
      "600": "#cf1c1c",
      "700": "#a81616",
      "800": "#8f1313",
      "900": "#690e0e",
    },
  },
  templates: [
    {
      type: "free-talk",
      titleKey: "dashboard.template.freeTalk.title",
      descriptionKey: "dashboard.template.freeTalk.description",
      backgroundColor: "brand.tertiary.main",
      buttonBackgroundColor: "#fff",
      borderColor: "#333",
      buttonTextColor: "#333",
      descriptionTextColor: "white",
    },
    {
      type: "role-play",
      titleKey: "dashboard.template.rolePlay.title",
      descriptionKey: "dashboard.template.rolePlay.description",
      backgroundColor: "brand.secondary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "topic",
      titleKey: "dashboard.template.topics.title",
      descriptionKey: "dashboard.template.topics.description",
      backgroundColor: "#0cbcfc",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "quiz",
      titleKey: "dashboard.template.quiz.title",
      descriptionKey: "dashboard.template.quiz.description",
      backgroundColor: "#fc6c6c",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "case",
      titleKey: "dashboard.template.caseStudy.title",
      descriptionKey: "dashboard.template.caseStudy.description",
      backgroundColor: "brand.primary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
  ],
};
